import * as React from 'react';
import * as classNames from 'classnames';
import Link from '../Link';

export interface INavigation {
  [key: string]: string | INavigation;
}

interface Props {
  navigation: INavigation;
  activeSlug: string;
}

const Navigation: React.StatelessComponent<Props> = ({ navigation, activeSlug }) => (
  <div className='sidebar-inverse'>
    <button
      className='navbar-toggler'
      type='button'
      data-toggle='collapse'
      data-target='#menu-docs'
      aria-controls='navbarsExampleDefault'
      aria-expanded='false'
      aria-label='Toggle navigation'
    >
      <i className='fas fa-bars' />
    </button>
    <ul className='categories collapse categories--style-1' id='menu-docs' data-children='.item'>
      {Object.keys(navigation).map(category => {
        const categoryValue = navigation[category];
        const isMainCategoryActive = isCategoryActive(categoryValue, activeSlug);

        if (typeof categoryValue === 'string') {
          return (
            <li className='item' key={category}>
              <Link to={parseMarkdownToLink(categoryValue)} data-parent='#menu-docs'>
                <span className={classNames('category-name strong-600', { active: isMainCategoryActive })}>
                  {category}
                </span>
              </Link>
            </li>
          );
        }

        const subCategoryId = getSubCategoryId(category);

        return (
          <li className='item' key={category}>
            <a
              href={`#${subCategoryId}`}
              data-toggle='collapse'
              data-parent='#menu-docs'
              aria-controls={subCategoryId}
              aria-expanded={isMainCategoryActive}
            >
              <span className={classNames('category-name strong-600', { active: isMainCategoryActive })}>
                {category}
              </span>
            </a>
            <ul id={subCategoryId} className={classNames('collapse', { show: isMainCategoryActive })} role='tabpanel'>
              {Object.keys(categoryValue).map(subCategory => (
                <li key={subCategory}>
                  <Link
                    to={parseMarkdownToLink(categoryValue[subCategory] as string)}
                    className={classNames({ active: isCategoryActive(categoryValue[subCategory], activeSlug) })}
                  >
                    {subCategory}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  </div>
);

export default Navigation;

function parseMarkdownToLink(markdown: string): string {
  if (markdown === 'index.md') {
    return '/docs/';
  }

  return '/docs/' + markdown.replace('.md', '');
}

function isCategoryActive(category: string | INavigation, activeSlug: string): boolean {
  const activeSlugMdName = activeSlug.replace('/docs/', '').replace('/', '.md');

  if (!activeSlugMdName && category === 'index.md') {
    return true;
  }

  if (typeof category === 'string') {
    return category === activeSlugMdName;
  }

  return !!Object.keys(category).find(subCategory => category[subCategory] === activeSlugMdName);
}

function getSubCategoryId(category: string): string {
  // https://regex101.com/r/pXGyYp/1
  return `navigation-${category.replace(/\W/g, '-')}`;
}
