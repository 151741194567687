import * as React from 'react';
import Helmet from 'react-helmet';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { graphql } from 'gatsby';

import Layout from '../layouts/Layout';
import FancyPageHeader from '../components/shared/FancyPageHeader';
import Navigation, { INavigation } from '../components/docs/Navigation';
import MarkdownAnchorIcon from '../components/markdown/MarkdownAnchorIcon';
import MarkdownImage from '../components/markdown/MarkdownImage';

import { IDocsArticle } from '../interfaces/Markdown';
import { getFeaturedImage } from '../utils/markdown';
import MarkdownLink from '../components/markdown/MarkdownLink';

interface Props {
  data: {
    markdownRemark: IDocsArticle;
  };
  pageContext: {
    navigation: INavigation;
    slug: string;
  };
}

// Style inspired by https://preview.webpixels.io/boomerang-v3.6.1/pages/blog/blog-post-sidebar.html
const DocsArticle: React.SFC<Props> = ({ data: { markdownRemark: post }, pageContext: { navigation, slug } }) => (
  <Layout>
    <Helmet>
      <title>{`${post.headings[0].value} · Docs`}</title>
      <meta name='description' content={post.excerpt} />
      <meta property='og:title' content={post.headings[0].value} />
      <meta property='og:description' content={post.excerpt} />
      <meta property='og:type' content='article' />
      <meta property='og:site_name' content='VersionPress' />
      <meta property='og:image' content={getFeaturedImage(post)} />
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content='@versionpress' />
    </Helmet>
    <FancyPageHeader title='Docs' titleLink='/docs/' subtitle='VersionPress.com hosting docs' className='blog-header' />
    <div className='blog slice container'>
      <div className='row'>
        <div className='col-lg-3 docs-navigation'>
          <Navigation navigation={navigation} activeSlug={slug} />
        </div>
        <div className='col-lg-9'>
          <div className='block block-post docs-post'>
            <div className='mb-4'>
              <h1 className='heading heading-2 strong-400 text-normal'>{post.headings[0].value}</h1>
            </div>
            <div className='block-body block-post-body'>
              {ReactHtmlParser(post.html, { transform: getTransform(slug) })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default DocsArticle;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 225)
      headings {
        value
      }
    }
  }
`;

/**
 * This logic can be seen as overcomplicated, but it has its reasons.
 * We need to keep @param slug in context, but we also need to pass 'transform' function to
 * 'parseNoteNode' to be able to properly parse the markdown.
 */
function getTransform(slug: string) {
  const transform = (node: any, index: number) => {
    if (node.name === 'p' && node.children[0].data && node.children[0].data.startsWith('!!!')) {
      return parseNoteNode(node, index, transform);
    }

    if (node.name === 'h1') {
      // We're rendering a title in a separate div
      return null;
    }

    if (node.name === 'a' && node.children[0].name === 'svg') {
      // Workaround for anchor "viewbox" and "fill-rule" errors
      // It's a bug in the "react-html-parser" library
      return <MarkdownAnchorIcon href={node.attribs.href} key={index} />;
    }

    if (node.name === 'a' && node.children[0].name !== 'svg') {
      let href: string = node.attribs.href;

      if (href.includes('.md')) {
        // It's possible to have 'some-tip.md#extra-tip' format
        href = `/docs/${href.replace('.md', '/')}`;
      }

      if (href.startsWith('#')) {
        href = slug + href;
      }

      return <MarkdownLink href={href} node={node} key={index} />;
    }

    if (node.name === 'img') {
      return <MarkdownImage node={node} key={index} />;
    }
  };

  return transform;
}

function parseNoteNode(node: any, index: number, transform: any) {
  const { children } = node;

  // https://regex101.com/r/tyXxm6/1
  const metadata = children[0].data.replace(/\n.*/, '');

  // Don't render metadata
  // https://regex101.com/r/bkdRZQ/1
  children[0].data = children[0].data.replace(/.*\n/, '');

  // https://regex101.com/r/NMzmxj/3
  const parsedMetadata = /!!! (\w*)\s?("(.*)")?/.exec(metadata);
  if (!parsedMetadata) {
    throw new Error(`Note format is not valid, ${parsedMetadata}`);
  }

  const type = parsedMetadata[1];
  const text = parsedMetadata[3] || 'Note';

  return (
    <div className={`docs-note ${type}`} key={index}>
      <div className={`docs-note-title ${type}`}>{text}</div>
      {convertNodeToElement(node, index, transform)}
    </div>
  );
}
